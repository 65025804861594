import {
  Alert,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { provisioningFormStyles } from "./componentStyles";
import { useState } from "react";
import { provisionTenant } from "../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";

const ProvisioningForm = (props) => {
  const classes = provisioningFormStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [provisionMessage, setProvisionMessage] = useState(null);
  const [provisioning, setProvisioning] = useState(false);
  const [formData, setFormData] = useState({
    accountName: "",
    databaseName: "",
    administratorFirstName: "",
    administratorLastName: "",
    administratorEmail: "",
    accountType: "sports",
  });
  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.innerContainer}>
        {provisionMessage && (
          <Box className={classes.messageContainer}>
            <Alert
              severity={provisionMessage === "Successful" ? "success" : "error"}
            >
              {provisionMessage}
            </Alert>
          </Box>
        )}
        <Box className={classes.fieldContainer}>
          <Typography variant="h3">Account Name</Typography>
          <TextField
            variant="standard"
            fullWidth
            placeholder="e.g. Ardorio"
            onChange={(e) => {
              setFormData({ ...formData, accountName: e.target.value });
            }}
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography variant="h3">Database Name</Typography>
          <TextField
            onChange={(e) => {
              setFormData({ ...formData, databaseName: e.target.value });
            }}
            variant="standard"
            fullWidth
            placeholder="e.g. ardorio or more-than-one-word-name"
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography variant="h3">Administrator First Name</Typography>
          <TextField
            variant="standard"
            fullWidth
            placeholder="Jansen"
            onChange={(e) => {
              setFormData({
                ...formData,
                administratorFirstName: e.target.value,
              });
            }}
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography variant="h3">Administrator Last Name</Typography>
          <TextField
            variant="standard"
            fullWidth
            placeholder="Peterson"
            onChange={(e) => {
              setFormData({
                ...formData,
                administratorLastName: e.target.value,
              });
            }}
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography variant="h3">Administrator Email</Typography>
          <TextField
            variant="standard"
            fullWidth
            placeholder="jansen@ardorio.co"
            onChange={(e) => {
              setFormData({ ...formData, administratorEmail: e.target.value });
            }}
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <Box className={classes.selectContainer}>
            <Typography variant="h3">Account Type</Typography>
            <Select
              variant="standard"
              onChange={(e) => {
                setFormData({ ...formData, accountType: e.target.value });
              }}
              value={formData.accountType}
              disableUnderline={true}
              MenuProps={{ disablePortal: true }}
              sx={{ width: "100%" }}
            >
              <MenuItem value="corporate">
                <Box className={classes.optionItemContainer}>Corporate</Box>
              </MenuItem>
              <MenuItem value="sports">
                <Box className={classes.optionItemContainer}>Sports</Box>
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <Box className={classes.submitButtonContainer}>
          {provisioning ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={() => {
                getAccessTokenSilently().then((t) => {
                  setProvisioning(true);
                  provisionTenant(t, formData)
                    .then((s) => {
                      setFormData({
                        accountName: "",
                        databaseName: "",
                        administratorFirstName: "",
                        administratorLastName: "",
                        administratorEmail: "",
                        accountType: "sports",
                      });
                      setProvisionMessage("Successful");
                      setProvisioning(false);
                      setTimeout(() => setProvisionMessage(null), 2000);
                    })
                    .catch((error) => {
                      setProvisionMessage("Failed, please try again");
                      setProvisioning(false);
                      setTimeout(() => setProvisionMessage(null), 2000);
                    });
                });
              }}
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProvisioningForm;
