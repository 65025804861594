import {
  Alert,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { leadsStyles } from "./leadsStyles";
import { useState } from "react";
import { addLead, getLeads } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";

const NewLeadModal = (props) => {
  const { open, setOpen, setLeads } = props;
  const classes = leadsStyles();
  const [formData, setFormData] = useState({
    leadName: "",
    phoneNumber: "",
    emailAddress: "",
    firstName: "",
    lastName: "",
  });
  const [submitClicked, setSubmitClicked] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const handleClick = async () => {
    setSubmitClicked(true);
    getAccessTokenSilently().then((t) => {
      addLead(t, formData)
        .then(() => {
          setFormData({
            leadName: "",
            phoneNumber: "",
            emailAddress: "",
            firstName: "",
            lastName: "",
          });
          getLeads(t, { page: 1 }).then((newResponse) => {
            setLeads(newResponse);
          });
          setOpen(false);
        })

        .catch((e) => {
          setSubmissionMessage(
            JSON.parse(e.response?.request?.response).message
          );
          setTimeout(() => setSubmissionMessage(null), 2000);
        });
    });
  };

  return (
    <Modal
      open={open}
      onClose={(e) => {
        e.stopPropagation();
        setOpen(false);
      }}
    >
      <Box className={classes.modalBase}>
        <Typography>New Lead</Typography>
        {submissionMessage && (
          <Box className={classes.messageContainer}>
            <Alert
              severity={
                submissionMessage === "Successful" ? "success" : "error"
              }
            >
              {submissionMessage}
            </Alert>
          </Box>
        )}
        <Box className={classes.modalContent}>
          <Box className={classes.fieldContainer}>
            <Typography variant="h3">Lead Name</Typography>
            <TextField
              variant="standard"
              fullWidth
              placeholder="e.g. Ardorio"
              error={submitClicked && !formData.leadName}
              onChange={(e) => {
                setFormData({ ...formData, leadName: e.target.value });
              }}
            />
          </Box>
          <Box className={classes.fieldContainer}>
            <Typography variant="h3">First Name</Typography>
            <TextField
              variant="standard"
              fullWidth
              placeholder="e.g. Ryan"
              error={submitClicked && !formData.firstName}
              onChange={(e) => {
                setFormData({ ...formData, firstName: e.target.value });
              }}
            />
          </Box>
          <Box className={classes.fieldContainer}>
            <Typography variant="h3">Last Name</Typography>
            <TextField
              variant="standard"
              fullWidth
              placeholder="e.g. Kwan"
              error={submitClicked && !formData.lastName}
              onChange={(e) => {
                setFormData({ ...formData, lastName: e.target.value });
              }}
            />
          </Box>
          <Box className={classes.fieldContainer}>
            <Typography variant="h3">Phone Number</Typography>
            <TextField
              variant="standard"
              fullWidth
              placeholder="e.g. 0426 607 430"
              onChange={(e) => {
                setFormData({ ...formData, phoneNumber: e.target.value });
              }}
            />
          </Box>
          <Box className={classes.fieldContainer}>
            <Typography variant="h3">Email Address</Typography>
            <TextField
              variant="standard"
              fullWidth
              placeholder="e.g. ryan@ardorio.co"
              onChange={(e) => {
                setFormData({ ...formData, emailAddress: e.target.value });
              }}
            />
          </Box>
        </Box>
        <Box className={classes.modalButtonContainer}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleClick}>Submit</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewLeadModal;
