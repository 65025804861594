import { Box, Typography } from "@mui/material";
import { noteItemStyles } from "../componentStyles";
import moment from "moment";

const NoteItem = (props) => {
  const { note } = props;
  const classes = noteItemStyles();
  return (
    <Box className={classes.container}>
      <Typography variant="h3">
        {moment(note.createdAt).format("DD MMM YYYY")}
      </Typography>
      <Typography variant="h2">{note.note}</Typography>
    </Box>
  );
};

export default NoteItem;
