import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { leadItemStyles } from "../componentStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { addNote, getNotes, provisionTenant } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import NoteItem from "./NoteItem";
import { leadNameToDatabaseName } from "../../helper";

const LeadItem = (props) => {
  const { lead } = props;
  const classes = leadItemStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [noteValue, setNoteValue] = useState("");
  const [provisioningTrial, setProvisioningTrial] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [myNotes, setMyNotes] = useState({});
  const [trialButtonDisabled, setTrialButtonDisabled] = useState(false);

  useEffect(() => {
    setTrialButtonDisabled(lead.accountType === "trial");
  }, []);

  const handleAddNote = async () => {
    getAccessTokenSilently().then((t) => {
      addNote(t, {
        leadId: lead._id,
        note: noteValue,
      })
        .then(() => {
          setNoteModalOpen(false);
          getNotes(t, {
            lead: lead._id,
          })
            .then((n) => {
              setMyNotes(n);
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          setErrorMessage(JSON.parse(e.response?.request?.response).message);
          setTimeout(() => setErrorMessage(null), 4000);
        });
    });
  };
  const handleProvisionTrial = async () => {
    setProvisioningTrial(true);
    getAccessTokenSilently().then((t) => {
      provisionTenant(t, {
        accountName: lead.leadName,
        databaseName: leadNameToDatabaseName(lead.leadName),
        administratorFirstName: lead.contactPersonFirstName,
        administratorLastName: lead.contactPersonLastName,
        administratorEmail: lead.emailAddress,
        accountType: "sports",
        isTrial: true,
        leadId: lead._id,
      })
        .then((s) => {
          setProvisioningTrial(false);
          setTrialButtonDisabled(true);
          getNotes(t, {
            lead: lead._id,
          })
            .then((n) => {
              setMyNotes(n);
            })
            .catch((e) => {
              console.log(e);
              setProvisioningTrial(false);
            });
        })
        .catch((error) => {
          console.log(error);
          setProvisioningTrial(false);
        });
    });
  };

  const handleExpand = async (e, v) => {
    if (v) {
      getAccessTokenSilently().then((t) => {
        getNotes(t, {
          lead: lead._id,
        })
          .then((n) => {
            setMyNotes(n);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    }
  };

  return (
    <Accordion onChange={handleExpand}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className={classes.outerContainer}>
          <Box className={classes.nameCell}>
            <Typography variant="h2">{lead.leadName}</Typography>
          </Box>
          <Box className={classes.contactDetails}>
            <Box className={classes.cell}>
              <Typography variant="h3">
                {lead.contactPersonFirstName} {lead.contactPersonLastName}
              </Typography>
            </Box>
            <Box className={classes.cell}>
              <Typography variant="h3">{lead.emailAddress}</Typography>
            </Box>
            <Box className={classes.cell}>
              <Typography variant="h3">{lead.phoneNumber}</Typography>
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Button
          onClick={() => {
            setNoteModalOpen(true);
          }}
        >
          Add Note
        </Button>
        {noteModalOpen && (
          <Modal
            open={noteModalOpen}
            onClose={(e) => {
              e.stopPropagation();
              setNoteModalOpen(false);
            }}
          >
            <Box className={classes.modalBase}>
              {errorMessage && (
                <Box sx={{ marginBottom: "0.8rem" }}>
                  <Alert
                    severity={
                      errorMessage === "Successful" ? "success" : "error"
                    }
                  >
                    {errorMessage}
                  </Alert>
                </Box>
              )}
              <TextField
                id="new-note"
                sx={{ width: "100%" }}
                multiline
                rows={4}
                placeholder="Type your note here..."
                onChange={(e) => {
                  setNoteValue(e.target.value);
                }}
              />
              <Box className={classes.modalButtonContainer}>
                <Button
                  onClick={() => {
                    setNoteModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={handleAddNote}>Submit</Button>
              </Box>
            </Box>
          </Modal>
        )}

        {provisioningTrial ? (
          <CircularProgress />
        ) : (
          <Button disabled={trialButtonDisabled} onClick={handleProvisionTrial}>
            Start Trial
          </Button>
        )}

        <Box>
          {myNotes.docs?.map((n) => (
            <NoteItem note={n} />
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LeadItem;
