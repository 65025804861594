import { makeStyles } from "@mui/styles";

export const leadsStyles = makeStyles({
  modalBase: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "1.5rem",
    borderRadius: 24,
    boxShadow: 24,
    "& .MuiTypography-h1": {
      margin: 0,
      marginLeft: "0.2rem",
      marginRight: "0.4rem",
      marginTop: "0.2rem",
      fontSize: "2rem",
      fontWeight: 400,
    },
    "& .MuiTypography-h3": {
      fontSize: "0.8rem",
      textTransform: "uppercase",
      color: "#6a6a6a",
      fontWeight: "500",
    },
    // "& .MuiTypography-h4": {
    //   margin: 0,
    //   fontSize: "1rem",
    //   fontWeight: 400,
    //   color: "#cb5050",
    // },
    // "& .MuiTypography-body1": {
    //   margin: 0,
    //   fontSize: "1rem",
    //   fontWeight: 400,
    //   color: "#cb5050",
    // },
  },
  modalButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  modalContent: {
    minWidth: 300,
  },
  fieldContainer: {
    padding: "1rem",
  },
});
