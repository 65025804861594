import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { homeStyles } from "./pageStyles";
import { useEffect, useState } from "react";
import { getTenants } from "../apiHelper";
import AccountComponent from "../components/AccountComponent";
import AccountHeaderComponent from "../components/AccountHeaderComponent";
import ProvisioningForm from "../components/ProvisioningForm";
import Leads from "../components/Leads";

const Home = () => {
  const classes = homeStyles();
  const [tenants, setTenants] = useState([]);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (tab === 1) {
      getTenants({ isTrial: true }).then((tenants) => {
        setTenants(tenants.data);
        setLoading(false);
      });
    }
    if (tab === 2) {
      getTenants().then((tenants) => {
        setTenants(tenants.data);
        setLoading(false);
      });
    }
  }, [tab]);
  return (
    <Box className={classes.outerContainer}>
      <Box>
        <Tabs
          onChange={(e, nv) => {
            setTab(nv);
          }}
          value={tab}
        >
          <Tab label={"Leads"} />
          <Tab label={"Trials"} />
          <Tab label={"Accounts"} />
          <Tab label={"Provision"} />
        </Tabs>
      </Box>
      {tab === 0 ? (
        <>
          <Leads />
        </>
      ) : null}
      {tab === 1 ? (
        <>
          <AccountHeaderComponent />
          {loading ? (
            <CircularProgress />
          ) : (
            tenants.map((t, i) => <AccountComponent key={i} account={t} />)
          )}
        </>
      ) : null}
      {tab === 2 ? (
        <>
          <AccountHeaderComponent />
          {loading ? (
            <CircularProgress />
          ) : (
            tenants.map((t, i) => <AccountComponent key={i} account={t} />)
          )}
        </>
      ) : null}
      {tab === 3 ? (
        <>
          <ProvisioningForm />
        </>
      ) : null}
    </Box>
  );
};

export default Home;
