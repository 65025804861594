import { Box, Button, Typography } from "@mui/material";
import { leadsComponentStyles } from "./componentStyles";
import { useEffect, useState } from "react";
import NewLeadModal from "./leads/NewLeadModal";
import { useAuth0 } from "@auth0/auth0-react";
import { getLeads } from "../apiHelper";
import LeadItem from "./leads/LeadItem";

const Leads = () => {
  const classes = leadsComponentStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [leads, setLeads] = useState({});
  useEffect(() => {
    getAccessTokenSilently().then((t) => {
      getLeads(t, { page: 1 }).then((l) => {
        setLeads(l);
      });
    });
  }, []);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 1) {
      if (leads.hasNextPage) {
        getAccessTokenSilently().then((t) =>
          getLeads(t, { page: leads.nextPage }).then((newResponse) => {
            setLeads({
              ...newResponse,
              docs: [...leads.docs, ...newResponse?.docs],
            });
          })
        );
      }
    }
  };

  return (
    <>
      <Box className={classes.outerContainer}>
        <Box className={classes.topActionsContainer}>
          <Button
            onClick={() => {
              setModalOpen(true);
            }}
            variant="contained"
          >
            + Add Lead
          </Button>
        </Box>

        <Box className={classes.innerContainer} onScroll={handleScroll}>
          {leads.docs?.map((l, i) => (
            <LeadItem lead={l} key={i} />
          ))}
        </Box>
      </Box>
      <NewLeadModal
        open={modalOpen}
        setOpen={setModalOpen}
        setLeads={setLeads}
      />
    </>
  );
};

export default Leads;
